<template>
    <BaseScene name="stage-guest-view" @leave="OnLeaveScene" @enter="OnEnterScene">

        <div class="mood-containers">
            <div 
                class="mood-container" 
                v-for="(mood, key) in moods" 
                :key="mood.src"
                :style="{'background-image': `url('${mood.src}')`}"
                :class="{visible: activeMood == key}"
            ></div>
        </div>

        <div class="panel-bg" :class="{visible: panelVisible}">
            <div class="panel">
                <p>{{ cms.get('translations').get('moods') }}</p>
                <div class="moods">
                    <div 
                        class="mood" 
                        v-for="(mood, key) in moods" 
                        :key="mood.src" 
                        :class="{active: activeMood == key}" 
                        @click="setMood(key)"
                    >
                        <div class="light">
                            <img :src="require('@/assets/images/panel-button-on.png')" v-if="activeMood == key">
                            <img :src="require('@/assets/images/panel-button-off.png')" v-else>
                        </div>
                        <div class="label">{{ cms.get('translations').get('mood-' + mood.title) }}</div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="activeMood != 'off' || true">
            <!-- <VideoTrigger 
                x="-7.5" 
                y="-18"
                :url="cms.get('stageguestview').get('trailers')"
                mode="array"
                :sound="ResourceManager.Get('laughs.rodrigo.4')" 
                title="Trailer"
            />

            <VideoTrigger 
                x="-2.5" 
                y="-18" 
                url="68v3hKoX1jk" 
                :sound="ResourceManager.Get('laughs.gundula.6')" 
                :delay="500"
                title="Livestream"
            />

            <URLTrigger 
                url="https://theaterdeslachens.de/" 
                x="2.5" 
                y="-18" 
                :sound="ResourceManager.Get('laughs.mike.4')"
            />    

            <VideoTrigger 
                x="7.5" 
                y="-18"
                :url="cms.get('stageguestview').get('experiments')"
                mode="array"
                :sound="ResourceManager.Get('laughs.nicole.10')"
            />                      -->
            <VideoTrigger 
                x="-7.5" 
                y="-18"
                :url="cms.get('stageguestview', 'de').get('trailers', 'de')"
                mode="array"
                :sound="ResourceManager.Get('laughs.rodrigo.4')" 
                title="Trailer"
            />

            <VideoTrigger 
                x="0" 
                y="-18" 
                url="68v3hKoX1jk" 
                :sound="ResourceManager.Get('laughs.gundula.6')" 
                :delay="500"
                title="Livestream"
            />

            <!-- <URLTrigger 
                url="https://theaterdeslachens.de/" 
                x="2.5" 
                y="-18" 
                :sound="ResourceManager.Get('laughs.mike.4')"
            />     -->

            <VideoTrigger 
                x="7.5" 
                y="-18"
                :url="cms.get('stageguestview', 'de').get('experiments', 'de')"
                mode="array"
                :sound="ResourceManager.Get('laughs.nicole.10')"
            />                     
        </template>
    </BaseScene>
</template>

<script>
import BaseScene from '../BaseScene.vue'
import { Howl } from 'howler';
import ResourceManager from "@/classes/resource-manager";
// import URLTrigger from '../triggers/URLTrigger.vue';
import VideoTrigger from '../triggers/VideoTrigger.vue';

export default {
    inject: {
        cms: {
            default: null,
        },
    },    
    components: {
        BaseScene,
        VideoTrigger,
        // URLTrigger
    },
    mounted() {

    },
    data() {
        return {
            ResourceManager,
            moods: {
                off: {
                    src: ResourceManager.Get("stagemoods.off"),
                    ambience: null,
                    title: 'off'
                }, 
                dark: {
                    src: ResourceManager.Get("stagemoods.dark"),
                    ambience: ResourceManager.Get("moods.dark"),
                    title: 'dark'
                }, 
                swamp: {
                    src: ResourceManager.Get("stagemoods.swamp"),
                    ambience: ResourceManager.Get("moods.swamp"),
                    title: 'swamp'
                }, 
                summer: {
                    src: ResourceManager.Get("stagemoods.summer"),
                    ambience: ResourceManager.Get("moods.summer"),
                    title: 'summer'
                }, 
                winter: {
                    src: ResourceManager.Get("stagemoods.winter"),
                    ambience: ResourceManager.Get("moods.winter"),
                    title: 'winter'
                }, 
                sunset: {
                    src: ResourceManager.Get("stagemoods.sunset"),
                    ambience: ResourceManager.Get("moods.sunset"),
                    title: 'sunset'
                }, 
                night: {
                    src: ResourceManager.Get("stagemoods.night"),
                    ambience: ResourceManager.Get("moods.night"),
                    title: 'night'
                } 
            },
            activeMood: 'off',
            settingMood: false,
            panelVisible: false,
            moodAudio: null
        };
    },
    methods: {
        setMood(mood) {
            if (this.settingMood) {
                return;
            }

            this.settingMood = true;

            if (this.moodAudio != null && this.moodAudio.playing()) {
                this.moodAudio.stop();
            }            

            const sound = new Howl({
                src: [ResourceManager.Get('shorts.spotlight')],
                sprite: {
                    short: [350, 2000]
                },
                volume: 1
            });

            const isOff = this.activeMood == 'off';
            if (!isOff) {
                sound.play('short');        
                this.activeMood = '';
            }

            if (mood != 'off') {
                setTimeout(() => {
    
                    sound.play('short');
                    this.activeMood = mood;

                    setTimeout(() => {
                        this.settingMood = false;

                        this.moodAudio = new Howl({
                            src: [this.moods[this.activeMood].ambience],
                            loop: true,
                            volume: 1
                        });         
                        
                        this.moodAudio.play();                    
                    }, 1500);    
                }, isOff ? 0 : 1500);
            } else {
                this.activeMood = 'off';
                this.settingMood = false;
            }
        }, 
        OnEnterScene() {
            setTimeout(() => {
                this.panelVisible = true;
            }, 1000);
        },
        OnLeaveScene() {
            this.panelVisible = false;

            if (this.moodAudio.playing()) {
                this.moodAudio.stop();
            }
        }
    }
}
</script>

<style lang="scss">
.scene-stage-guest-view {
    .mood-containers {
        .mood-container {
            width: 100%;
            height: 100%;
            position: fixed;
            opacity: 0;
            transition: 1s;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            &.visible {
                opacity: 1;
            }
        }
    }

    .panel-bg {
        width: 225px;
        height: 514px;
        left: 20px;
        top: -514px;
        position: fixed;
        background-image: url('../../assets/images/panel-big.svg');
        transition: .35s;

        &.visible {
            top: -15px;
        }

        .panel {
            position: absolute;
            bottom: 18px;
            left: 20px;
            width: 100%;

            p {
                margin-top: 0;
                margin-bottom: 15px;
            }

            .moods {
                display: flex;
                flex-direction: column;

                .mood {
                    margin-bottom: 1px;
                    color: white;
                    font-size: 14px;
                    cursor: pointer;
                    display: flex;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .label {
                        margin-top: 2px;
                    }

                    .light {
                        font-size: 0px;
                        line-height: 0px;
                    }

                    &:nth-child(1) {
                        margin-bottom: 3px;
                        position: absolute;
                        top: -3px;
                        right: 50px;
                    }

                    &:nth-child(4) {
                        margin-bottom: 15px;
                    }

                }
            }            

            p {
                color: white;
                font-weight: bold;
                font-size: 14px;
                text-transform: uppercase;
                margin-left: 10px;            
            }
        }

        // .mood {
        //     padding: 5px 10px;
        //     background: #333;
        //     margin-bottom: 1px;
        //     color: black;
        //     font-weight: bold;
        //     cursor: pointer;
        //     user-select: none;

        //     &.active {
        //         color: red;
        //     }

        //     &:last-child {
        //         margin-bottom: 0;
        //     }
        // }
    }
}
@media (max-width: 800px) and (max-height: 400px) {

.scene-stage-guest-view {
    .panel-bg {
        zoom: 0.8;

         &.visible {
            top: -155px;
        }

        .panel {
            bottom: 9px;

            p {
                margin-bottom: 7px;
            }
        }
    }
}

}
</style>